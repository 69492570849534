import React from "react"

/** Components */
import Layout from "@components/layout"
import SEO from "../components/seo"
import TitleCard from "@components/TitleCard"
import TextBlock from "@components/TextBlock"
import Block from "@components/Block"
import BulletBlock from "@components/BulletBlock"
import IntroBlock from "@components/IntroBlock"
import ExampleBlock from "@components/ExampleBlock"
import CheckboxBlock from "@components/CheckboxBlock"
import PromoBlock from "@components/PromoBlock"
import Navigation from "@components/Navigation"
import ShareIcons from "@components/ShareIcons"
import SVGImage from "@components/SvgImage"

/** Helpers */
import TextBlockQuery from "../helpers/query-text-block"
import TitleBlockQuery from "../helpers/query-title-block"
import IntroBlockQuery from "../helpers/query-intro-block"
import ExampleBlockQuery from "../helpers/query-example-block"
import BulletBlockQuery from "../helpers/query-bullet-block"
import CheckboxBlockQuery from "../helpers/query-checkbox-block"

const Issue2 = ({ pageContext }) => {
  const editionIdentifier = "allMsIssue3Data"
  const textBlockData = TextBlockQuery(editionIdentifier)
  const introBlockData = IntroBlockQuery(editionIdentifier)
  const titleBlockData = TitleBlockQuery(editionIdentifier)
  const exampleBlockData = ExampleBlockQuery(editionIdentifier)
  const bulletBlockData = BulletBlockQuery(editionIdentifier)
  const checkboxBlockData = CheckboxBlockQuery(editionIdentifier)

  return (
    <Layout issueNumber="3">
      <Navigation />
      <SEO
        title="The Fight For Facts"
        description="A brand-new, monthly briefing to help you navigate this wild New Normal."
        issueNumber={3}
      />

      {/* intro section */}
      <div className="columns is-gapless is-multiline reversed-mobile">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[0]}>
            <ShareIcons shareUrl="https://makeshift.trendwatching.com/issue-3" />
          </IntroBlock>
        </div>
        <div className="column is-one-third">
          <Block background={titleBlockData[0].background_colour}>
            <TitleCard {...titleBlockData[0]} />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="2D8CFF" noPadding svgPosition="top">
            <SVGImage name="ms3-intro-cover-page" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="2D8CFF" noPadding svgPosition="center">
            <SVGImage name="ms3-intro-left" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="2D8CFF" noPadding svgPosition="center">
            <SVGImage name="ms3-intro-right" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[0].background_colour}>
            <TextBlock {...textBlockData[0]} />
          </Block>
        </div>
      </div>

      {/* setting the scene section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[1].background_colour}>
            <TitleCard {...titleBlockData[1]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[0].background_colour}>
            <BulletBlock {...bulletBlockData[0]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-news" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[1].background_colour}>
            <BulletBlock {...bulletBlockData[1]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[2].background_colour}>
            <BulletBlock {...bulletBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F6E9B2" svgPosition="center">
            <SVGImage name="ms3-bullet-conspiracy" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[3].background_colour}>
            <BulletBlock {...bulletBlockData[3]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-global-left" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-global-right" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[4].background_colour}>
            <BulletBlock {...bulletBlockData[4]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[5].background_colour}>
            <BulletBlock {...bulletBlockData[5]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-global" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[6].background_colour}>
            <BulletBlock {...bulletBlockData[6]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[7].background_colour}>
            <BulletBlock {...bulletBlockData[7]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F6E9B2" svgPosition="center">
            <SVGImage name="ms3-bullet-socials" directory="svg-blocks" />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={bulletBlockData[8].background_colour}>
            <BulletBlock {...bulletBlockData[8]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-action" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={bulletBlockData[9].background_colour}>
            <BulletBlock {...bulletBlockData[9]} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="F5DC5A" svgPosition="center">
            <SVGImage name="ms3-bullet-alarm" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="000000" svgPosition="center">
            <SVGImage name="ms3-bullet-lead" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[1].background_colour}>
            <TextBlock {...textBlockData[1]} />
          </Block>
        </div>
      </div>

      {/* Brands in Action section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[2].background_colour}>
            <TitleCard {...titleBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[2].background_colour}>
            <TextBlock {...textBlockData[2]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="FF9AAA" svgPosition="center">
            <SVGImage name="ms3-brands-action" directory="svg-blocks" />
          </Block>
        </div>
      </div>

      {/* Examples section */}
      {exampleBlockData.map((example) => {
        return (
          <ExampleBlock
            key={example.order}
            colors={{ brandText: "#F6E9B2", contentBackground: "#FF9AAA", cardBackground: "#FF4646" }}
            {...example}
          />
        )
      })}

      {/* Over to you section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background={titleBlockData[3].background_colour}>
            <TitleCard {...titleBlockData[3]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background={textBlockData[3].background_colour}>
            <TextBlock {...textBlockData[3]} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF" svgPosition="bottom">
            <SVGImage name="ms2-over-you" directory="svg-blocks" />
          </Block>
        </div>
      </div>

      {/* Checklists section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="2D8CFF" svgPosition="center">
            <SVGImage name="ms3-checkbox-more-than-words" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="2D8CFF">
            <CheckboxBlock {...checkboxBlockData[0]} order={1} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="2D8CFF">
            <CheckboxBlock {...checkboxBlockData[1]} order={2} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="50A5FF" svgPosition="center">
            <SVGImage name="ms3-checkbox-science" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[2]} order={3} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[3]} order={4} isSubItem />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="2D8CFF" svgPosition="center">
            <SVGImage name="ms3-checkbox-accuracy" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="2D8CFF">
            <CheckboxBlock {...checkboxBlockData[4]} order={5} />
          </Block>
        </div>
        <div className="column is-one-third is-hidden-mobile">
          <Block background="2D8CFF"></Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="50A5FF" svgPosition="center">
            <SVGImage name="ms3-checkbox-politics" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[5]} order={6} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[6]} order={7} />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="2D8CFF" svgPosition="center">
            <SVGImage name="ms3-checkbox-talks" directory="svg-blocks" />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="2D8CFF">
            <CheckboxBlock {...checkboxBlockData[7]} order={7} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="2D8CFF">
            <CheckboxBlock {...checkboxBlockData[8]} order={8} isSubItem />
          </Block>
        </div>
      </div>
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[9]} order={9} />
          </Block>
        </div>
        <div className="column is-one-third">
          <Block background="50A5FF">
            <CheckboxBlock {...checkboxBlockData[10]} order={10} isSubItem>
              <ShareIcons shareUrl="https://makeshift.trendwatching.com/issue-3" />
            </CheckboxBlock>
          </Block>
        </div>
        <div className="column is-one-third">
          <div style={{ backgroundColor: "#50A5FF", padding: 0, position: "relative", height: "100%" }}>
            <div
              className="background-split"
              style={{ background: "linear-gradient(to right bottom, #50A5FF00 50%, #F5DC5A 50%)", height: "100%" }}
            ></div>
          </div>
        </div>
      </div>

      {/* Outro section */}
      <div className="columns is-gapless is-multiline">
        <div className="column is-one-third">
          <IntroBlock {...introBlockData[1]} outroThanks thanksBgColor="#FF9AAA" />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            isPreviousEditions
            bgColor="FF4646"
            sectionTitle="Here's the opportunities you may have missed."
            buttonUrl="https://trendwatching.com/makeshift"
            issueNumber={2}
          />
        </div>
        <div className="column is-one-third">
          <PromoBlock
            buttonUrl="#"
            bgColor="FF4646"
            sectionTitle="Get opportunities that matter. Monthly. Always."
            formCopy="Join 100,000+ future-focused professionals in 180 countries already receiving a world of meaningful opportunities. Weekly."
            hasSvgTitle
          />
        </div>
      </div>
    </Layout>
  )
}

export default Issue2
